Vue.component("row-info", {
    props: ["label", "content"],
    template: '<div class="info"><span class="label">{{ label }}: </span><span class="content" v-html="content"></span></div>'
});

Vue.component("row-link", {
    props: ["title", "label", "path", "desc"],
    template: '<div class="info"><span v-if="title" class="label">{{ title }}:</span><span class="content"> \
        <i class="fa fa-link"></i> <a :href="path" target="_blank" :title="title">{{ label }}</a><span v-if="desc"> ({{desc}})</span></span></div>'
});

Vue.component("row-tagline", {
    props: ["label", "tags"],
    template: '<div class="info"><span class="label">{{ label }}:</span><span class="content"> \
        <span class="tag" v-for="tag in tags">{{tag}}</span> \
    </span></div>'
});

Vue.component("row-skills", {
    props: ["label", "tags"],
    template: '<div class="info"><span class="label">{{ label }}:</span><span class="content"> \
            <span class="tag" v-for="(s,t) in tags" :title="\'Score: \' + s" >{{t}}</span> \
    </span></div>'
});

Vue.component("more", {
    props: ["checkvar", "label"],
    template: '<button v-show="!eval(checkvar)" @click="alert(' + this.props + ');">{{label}}</button>'
});

for (var s in sections) {
    sections[s].state = {
        hidden: false,
        collapsed: false,
        maximized: false
    };
    sections[s].showMore = false;
}

var App = new Vue({
    el: "#app",
    data: {
        title: title,
        updated: updated,
        sections: sections,
        user: "",
        password: "",
        dir: [],
        enableBackup: true,
        numbers: [],
        gameOn: true,
        console: {
            area: {},
            content: "",
            prompt: " ", // "> "
            caret: "█", // 219
            line: "",
            history: [],
            historyPos: 0,
            line: "",
            command: "",
            callbacks: {
                before: [],
                after: []
            },
            mask: false,
            access: {
                "317a58affea472972b63bffdd3392ae0": "5cfa2f5fee1690519c11785234458bf4",
                "2a52334798a6386d01f85f74a5fd3d3a": "bafd89c8384fd66572a391e900704fee"
            },
            key: "317a58asdKM92_63bffdd3392ae0",
            colors: {
                normal: "#999999",
                error: "#BB0000",
                ok: "#00AA00",
                AI: "violet"
            },
            isLogged: false,
            lastPrompt: 0
        }
    },

    mounted() {

        this.restore();

        this.console.area = document.getElementById("console-area");

        this.console.area.focus();
        this.write("*** IrpC System v1.0 ***", true, "AI");
        this.write("Digita help per una lista di comandi", true, "normal");

        this.newLine();

        this.request("getfiles", {}, (data) => {
            this.dir = data.files;
        });

        for (var n = 1; n <= 280; ++n) {
            this.numbers.push(n);
        }

        this.console.area.focus();
    },

    methods: {

        backup: function() {

            if (!this.enableBackup) return false;

            var data = {
                user: this.user,
                password: this.password,
                sections: this.sections,
                console: {
                    prompt: this.console.prompt,
                    history: this.console.history,
                    historyPos: this.console.historyPos,
                    mask: this.console.mask,
                    isLogged: this.console.isLogged,
                    lastPrompt: this.console.lastPrompt,
                }
            }
            localStorage.setItem("data", btoa(JSON.stringify(data)));
        },

        restore: function() {

            if (!this.enableBackup) return false;

            if (localStorage.getItem("data") === null)
                return false;

            var data = JSON.parse(atob(localStorage.getItem("data")));

            this.user = data.user;
            this.password = data.password;
            this.sections = data.sections;
            for (key in data.console) {
                this.console[key] = data.console[key];
            }
        },

        clearBackup: function() {
            if (!this.enableBackup) return false;
            localStorage.removeItem('data');
        },

        consoleType: function($event) {
            var key = ($event.key || $event.which || $event.data);

            switch (key) {
                case "Shift":
                case "Control":
                case "Alt":
                case "Escape":
                case "AltGraph":
                case "Insert":
                case "Delete":
                case "Home":
                case "PageDown":
                case "PageUp":
                case "End":
                case "ScrollLock":
                case "Pause":
                case "NumLock":
                case "ArrowLeft":
                case "ArrowRight":
                case "Meta":
                case "ContextMenu":
                case "CapsLock":
                case "Unidentified":
                case "undefined":
                case undefined:
                case "Undefined":
                case "Tab":
                case "F1":
                case "F2":
                case "F3":
                case "F4":
                //case "F5":
                case "F6":
                case "F7":
                case "F8":
                case "F9":
                case "F10":
                case "F11":
                case "F12":
                    break;

                case "F5":
                    if (confirm('Vuoi ricaricare la finestra? la memoria della console andrà persa..')) {
                        location.reload();
                    }
                    break;

                case "Enter":
                    this.clearCaret();
                    if (this.console.line.trim() != "" && !this.console.mask && this.console.line != this.console.history[this.console.historyPos - 1]) this.sendLineToHistory(this.console.line);
                    this.console.historyPos = this.console.history.length;
                    this.write("", true);
                    this.consoleCommand(this.console.command + this.console.line);
                    break;

                case "Backspace":
                    if (this.console.line.length > 0) {
                        this.clearCaret();
                        this.console.line = this.console.line.slice(0, -1);
                        this.console.content = this.console.content.slice(0, -1) + this.getCaret();
                        //this.content += this.caret;
                    }
                    break;

                case "ArrowUp":
                    if (this.console.historyPos > 0) {
                        this.clearLine();
                        this.console.historyPos -= 1;
                        this.console.line = this.console.history[this.console.historyPos];
                        this.write(this.console.line + this.getCaret());
                    }
                    break;

                case "ArrowDown":
                    if (this.console.historyPos < this.console.history.length - 1) {
                        this.clearLine();
                        this.console.historyPos += 1;
                        this.console.line = this.console.history[this.console.historyPos];
                        this.write(this.console.line + this.getCaret());
                    }
                    break;

                default:
                    this.clearCaret();
                    this.write((!this.console.mask ? key : "*") + this.getCaret());
                    this.console.line += key;
            }
        },

        consoleClick: function($event) {
            this.clearCaret();
            this.setCaretPos($event.target, this.console.content.length + 1);
            this.console.content += this.getCaret();
            this.scrollToBottom();
        },

        newLine: function() {
            this.write(this.getPrompt());
            this.console.lastPrompt = this.console.content.length;
            this.write(this.getCaret());
            $this = this;
            setTimeout(function() {
                $this.scrollToBottom();
            }, 100);
        },

        scrollToBottom: function() {
            this.console.area.scrollTop = this.console.area.scrollHeight;
        },

        sendLineToHistory: function(line) {
            this.console.history.push(line.trim());
        },

        getWordsFrom: function(line, start) {
            words = line.split(" ");
            var value = line;
            for (n = 0; n < start; n++) {
                if (n > words.length - 1) continue;
                value = value.replace(words[n], "");
            }
            return value.trim();
        },

        consoleCommand: function(line) {
            var response = "";
            var words;
            var command;
            var mood = "normal";

            this.console.line = "";
            this.console.command = "";

            if (line != "") {
                words = line.split(" ");
                command = words[0].toLowerCase();
                command = command.replace(/\?/g, '');

                switch (command) {
                    case "clear":
                    case "cls":
                        this.console.content = "";
                        break;

                    case "@input":
                        if (typeof words[1] !== "undefined" && typeof this[words[1]] !== "undefined") {
                            this[words[1]] = this.getWordsFrom(line, 2);
                            mood = "ok";
                            //response = 'Ok, ho memorizzato ' + value;
                        }
                        this.console.mask = false;
                        break;

                    case "comprimi":
                    case "collapse":
                        for (sec in this.sections) {
                            if (sec == "console") continue;
                            this.sections[sec].state.collapsed = true;
                        }
                        mood = "ok";
                        response += "Ok, comprimo tutte le sezioni";
                        break;

                    case "maximize":
                        $('body').addClass('full-console');
                        break;

                    case "restore":
                        $('body').removeClass('full-console');
                        break;

                    case "espandi":
                    case "expand":
                        for (sec in this.sections) {
                            if (sec == "console") continue;
                            this.sections[sec].state.collapsed = false;
                        }
                        mood = "ok";
                        response += "Ok, espando tutte le sezioni";
                        break;

                    case "prompt":
                        if (typeof words[1] !== "undefined") {
                            if (words[1] == "empty" || words[1] == " " || words[1] == "") {
                                this.console.prompt = "";
                                mood = "ok";
                                response += "Prompt eliminato. Welcome to 80' :)";
                            } else {
                                this.console.prompt = words[1] + " ";
                                mood = "ok";
                                response += "Prompt impostato a '" + words[1] + "'";
                            }
                        } else {
                            this.getManual("prompt");
                            return;
                        }
                        break;

                    case "aiuto":
                    case "help":
                    case "man":
                        if (typeof words[1] !== "undefined") {
                            this.getManual(words[1]);
                        } else {
                            this.getManual("");
                        }
                        return;

                    case "login":
                        this.newLine();
                        this.login();
                        return;

                    case "halt":
                    case "exit":
                    case "logout":
                        if (this.logout()) {
                            mood = "ok";
                            response = "Ho effettuato il logout. Ciao e grazie!";
                        } else {
                            mood = "AI";
                            response = "Ma logout da cosa, che non sei loggato.. ";
                        }
                        break;

                    case "download":
                    case "scarica":
                    case "mostra":
                    case "mostrami":
                    case "show":
                        if (!this.logged) {
                            mood = "AI";
                            response += "Fai il login e poi ne riparliamo..";
                        } else if (typeof words[1] !== "undefined") {
                            let file = words[1].replace(/"/g, '');
                            if (this.dir.indexOf(file) == -1) {
                                mood = "error";
                                response += "Niente da fare, file inesistente.";
                            } else {
                                mood = "ok";
                                response += "Ok, aprirò il file in una nuova finestra.";
                                window.open("files/" + words[1].replace(/"/g, ''));
                            }

                        } else {
                            this.getManual("download");
                            return;
                        }
                        break;

                    case "print":
                    case "echo":
                        words.shift();
                        if (words[0].substring(0, 1) == '"') {
                            response += words.join(" ").replace(/"/g, '');
                        } else if (typeof words[0] == "undefined") {
                            mood = "error";
                            response = "?SYNTAX ERROR<br>READY.";
                        } else if (typeof this[words[0]] !== "undefined" && this[words[0]] != "") {
                            if (words[0] == "password") {
                                mood = "AI";
                                response += "All work and no play makes Jack a dull boy.";
                            } else {
                                response += this[words[0]];
                            }
                        } else {
                            response += 0;
                        }

                        break;

                    case "padroneggi":
                    case "sai":
                    case "conosci":
                        words.shift();
                        var sentence = words.join(" ");
                        sentence = sentence.replace(/\?/g, '');
                        if (typeof sentence === "undefined" || sentence == "") {
                            mood = "AI";
                            response += "So di non sapere.";
                        } else if (!this.logged) {
                            mood = "AI";
                            response += "Risentiamoci dopo il login..";
                        } else {
                            mood = "AI";
                            response += this.experience(sentence);
                        }

                        break;

                    default:

                        if (!(this.gameOn && GAME.command(line))) {
                            var $this = this;
                            words.shift();
                            this.request(command, words, function(data){
                                if (data.result == 'OK') {
                                    mood = data.mood;
                                    response += data.text;
                                } else {
                                    mood = "error";
                                    response = "?SERVER ERROR<br>READY.";
                                }
                                $this.write(response, true, mood);
                                $this.newLine();
                            });
                            return;
                        }
                }
            }

            if (this.console.callbacks.before.length > 0) {
                this.console.callbacks.before.pop()();
            }

            if (response != "") {
                this.write(response, true, mood);
            }

            this.newLine();

            if (this.console.callbacks.after.length > 0) {
                this.console.callbacks.after.pop()();
            }

            this.backup();
        },

        experience(skill) {
            skillLC = skill.toLowerCase();
            found = false;
            var score = 0;
            skills = [];

            for (skr in this.sections.about.content.skills) {
                for (sk in this.sections.about.content.skills[skr]) {
                    skills[sk] = this.sections.about.content.skills[skr][sk];
                }
            }

            for (sk in this.sections.about.content.skillsextra) {
                skills[sk] = this.sections.about.content.skillsextra[sk];
            }

            for (sk in skills) {
                if (sk.toLowerCase() == skill.toLowerCase()) {
                    found = true;
                    score = skills[sk];
                }
            }
            if (!found)  return "Non credo di conoscere " + skill;

            if (score == 0) return "Lo conosco di nome.";
            if (score <= 4) return "Ho cominciato a studiarlo.";
            if (score > 4 && score <= 6) return "Abbastanza, ma vorrei migliorare.";
            if (score > 6 && score <= 8) return "Conosco " + skill + " piuttosto bene.";
            if (score > 8 && score <= 10) return "Ho molta esperienza in " + skill +".";
            if (score > 10) return "Ho una conoscenza eccezionale di " + skill + ".";
        },

        request: function(command, params, callback) {
            params["c"] = command.toLowerCase();

            params["k"] = this.getKey();
            var http = new XMLHttpRequest();
            http.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    callback(JSON.parse(this.responseText));
                }
            };
            http.open("POST", "cmd/");
            http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            var reqa = [];
            for (key in params) reqa.push(key + "=" + params[key]);
            http.send(reqa.join("&"));
        },

        getKey: function() {
            return md5(this.console.key + parseInt(new Date().getTime() / 100000));
        },

        getManual: function(command) {
            var $this = this;
            var response = '';
            var mood = 'normal';
            this.request("help", {argument: command}, function(data){
                if (data.result == 'OK') {
                    mood = data.mood;
                    response += data.text;
                } else {
                    mood = "error";
                    response = "?SERVER ERROR<br>READY.";
                }
                $this.write(response, true, mood);
                $this.newLine();
            });
        },

        clearCaret: function() {
            this.console.content = this.console.content.replace(new RegExp(this.getCaret(), "g"), "");
            //this.console.content = this.console.content.replace(this.getCaret(), '');
        },

        clearLine: function() {
            this.console.content = this.console.content.substring(0, this.console.lastPrompt);
        },

        clearLineAndPrompt: function() {
            this.console.content = this.console.content.substring(0, this.console.lastPrompt - this.console.prompt.length - 3);
        },

        input: function(params) {
            if (!params.text || !params.variable) return;
            this.clearCaret();
            this.line = params.text + " ";
            this.write(params.text + " " + this.getCaret());
            this.console.command = "@input " + params.variable + " ";
            if (params.before) this.console.callbacks.before.push(params.before);
            if (params.after) this.console.callbacks.after.push(params.after);
            this.console.mask = params.mask;
        },

        write: function(text, cr, color) {
            if (text) {
                this.console.content += (color ? '<span class="console-' + color + '">' : "") + text + (color ? "</span>" : "");
            }
            if (cr) this.console.content += "<br>";
        },

        getPrompt: function() {
            if (!this.console.prompt) return "";
            return '<span class="prompt">' + (this.isLogged ? this.user + "@IrpC" : "") + this.console.prompt + "</span>";
        },

        getCaret: function() {
            return '<span class="caret blink">' + this.console.caret + "</span>";
        },

        setCaretPos: function(el, pos) {
            if (el !== null) {
                if (el.createTextRange) {
                    var range = el.createTextRange();
                    range.move("character", pos);
                    range.select();
                    return true;
                } else {
                    if (el.selectionStart || el.selectionStart === 0) {
                        el.focus();
                        el.setSelectionRange(pos, pos);
                        return true;
                    } else {
                        el.focus();
                        return false;
                    }
                }
            }
        },

        logout: function() {
            if (!this.isGranted(this.user, this.password)) return false;
            this.user = "";
            this.password = "";
            $this.isLogged = false;
            this.clearBackup();
            return true;
        },

        login: function() {
            $this = this;
            this.input({
                text: "Inserisci l'utente:",
                variable: "user",
                before: function() {
                    if (!$this.user) return;
                    if (!$this.isUser($this.user)) {
                        $this.write("Scusa, ma chi sei?", true, "AI");
                        $this.user = "";
                    }
                },
                after: function() {
                    if ($this.user == "") {
                        return;
                    }
                    $this.input({
                        text: "Inserisci anche la password:",
                        variable: "password",
                        mask: true,
                        before: function() {
                            if ($this.isGranted($this.user, $this.password)) {
                                $this.write("Benvenuto, " + $this.user + ", hai accesso alle informazioni riservate.", true, "ok");
                                $this.isLogged = true;

                                $this.request("inf", {
                                    u: md5($this.user),
                                    p: md5($this.password)
                                }, (result) => {
                                    for (sec in result.sections) {
                                        for (label in result.sections[sec]) {
                                            $this.sections[sec].content[label] = result.sections[sec][label];
                                        }
                                    }

                                    $this.backup();
                                });

                            } else {
                                $this.write("Riprova e controlla.", true, "error");
                            }
                        }
                    });
                }
            });
        },

        isGranted: function(user, pwd) {
            return !(typeof this.console.access[md5(user)] === "undefined") && md5(pwd) == this.console.access[md5(user)];
        },

        isUser: function(user) {
            return !(typeof this.console.access[md5(user)] === "undefined");
        }
    },
    computed: {
        logged: function() {
            return this.isGranted(this.user, this.password);
        }
    },
    watch: {}
});

/* document.body.onkeypress = function(event) {
    console.info(event.keyCode);
    event.preventDefault();
    if (event.keyCode == 116) {
        if (confirm('Vuoi ricaricare la finestra? la memoria della console andrà persa..')) {
            location.reload();
        }
        return false;
    }
} */