var updated = "03.07.2018";
var title ="Curriculum di Stefano Caronia";
var sections = {
	informations: {
		label: "Informazioni",
		icon: "fa-address-card",
		content: {
			info: {
				nome: "Stefano Caronia",
				email: "stefano.caronia@gmail.com",
				website: "https://stefanocaronia.it",
				nazionalità: "Italiana"
			},
			links: [
				{
                    title: "Github",
                    label: "https://github.com/stefanocaronia",
                    path: "https://github.com/stefanocaronia"
				},
                {
                    title: "Linkedin",
                    label: "https://www.linkedin.com/in/stefanocaronia/",
                   	path: "https://www.linkedin.com/in/stefanocaronia/"
				}
			]
		}
	},
	work: {
		label: "Lavoro",
		icon: "fa-building",
		content: {},
		logged: true
	},
	freelance: {
		label: "Freelance",
		icon: "fa-handshake",
		content: {},
		logged: true
	},
	projects: {
		label: "Progetti",
		icon: "fa-lightbulb",
		content: {},
		logged: true
	},
	about: {
		label: "Qualcosa su di me",
		icon: "fa-binoculars",
		content: {
			text: "Ho una passione per i computer e la programmazione che risale all'infanzia, e mi sono poi specializzato nella realizzazione di siti web.",
			skills: {
				"Linguaggi": {"Php": 9, "Java": 6, "Jsp": 4, "Javascript": 8, "c++": 6, "c#": 6, "Html5": 9, "Css3": 8, "Bash Linux": 6},
				"Framework e librerie": {"jQuery": 9, "Angular": 6,  "Ionic": 6, "Laravel": 4, "Symfony": 3, "Vue.js": 5, "ReactJs": 1},
				"Database": {"Access": 8, "MySQL": 9, "SQLite": 8, "MongoDB": 5},
				"Game Developing":  {"OpenGL": 5, "SDL": 6, "Box2D": 6, "Unity": 6, "Unreal": 3},
				"Versioning": {"Git": 8},
				"IOT": {"Raspberry PI": 4, "Arduino": 4},
				"IDE": {"Eclipse": 6, "Visual Studio": 6, "Code::blocks": 7, "Notepad++": 10, "MonoDevelop": 6, "Visual Studio Code": 9},
				"Grafica": {"Paint Shop Pro": 8, "Fireworks": 8, "Photoshop": 6, "Illustrator": 5, "Blender": 6, "Inkscape": 5},
				"Audio e video": {"Cubase": 9, "Audition": 6, "WaveLab": 6, "Reason": 5, "Ableton Live": 6, "Premiere": 6, "After Effects": 5},
				"Ufficio": {"Word": 10, "Excel": 9, "Access": 9, "Publisher": 8, "PowerPoint": 7}
			},
			langs: {
				"Lingue conosciute": {"Italiano (madrelingua)": 10, "Inglese": 8},
			},
			skillsextra: {
				"Inglese": 8,
				"Italiano": 10
			}
		}
	},
	education: {
		label: "Formazione",
		icon: "fa-graduation-cap",
		content: {
			"Scuola superiore": "Diploma di Maturità Scientifica",
			"Università": "Facoltà di Lettere e Filosofia",
			"Musica": "Diploma in Teoria e Solfeggio"
		}
	},
	console: {}
}