var GAME = new Adventure();

var gamedata = {
    items: {},
    characters: {},
    rooms: {}
};

gamedata.items.scrignoDorato = new Container({
    name: "Scrigno Dorato",
    description: "Un'antico scrigno con finiture dorate e fatto di legno vecchio",
    articolo: "lo",
    state: State.LOCKED,
    key: "chiave rossa",
    capacity: 4,
    volume: 4,
    items: [
        new Item({
            name: "cartoncino",
            articolo: "un",
            volume: 0
        }),
        new Item({
            name: "penna",
            description: "una penna pilot blu",
            articolo: "una",
            volume: 1
        }),
        new Item({
            name: "dado",
            articolo: "un",
            volume: 1
        }),
    ]
});

gamedata.items.portafoglio = new Container({
    name: "portafoglio",
    description: "Un portafoglio Eastpak",
    articolo: "il",
    state: State.OPEN,
    capacity: 0,
    volume: 0, // un portafoglio può contenere solo oggetti senza peso/volume
    items: [
        new Item({
            name: "banconota",
            articolo: "una",
            description: "una banconota finta",
            volume: 0
        }),
    ]
});

gamedata.items.mela = new Item({
    name: "mela",
    description: "Una mela rossa succosa",
    articolo: "una",
    volume: 1,
    onMangia: function() {
        GAME.write("Mangi la mela e ti senti meglio.");
        GAME.room.container.delete(this.name);
        GAME.room.container.add(new Item({
            name: "torsolo di mela",
            volume: 1
        }));

        GAME.player.getHealed(4);
    }
});

gamedata.characters.troll = new Character({
    name: "Troll Mostruoso",
    description: "Un terribile essere che sembrava fatto di pietra",
    articolo: "un",
    maxHealth: 10,
    health: 10,
    aggressive: true,
    attackPower: 6,
    items: [
        new Item({
            name: "calzino puzzolente",
            articolo: "un"
        })
    ],
    onMeet: function() {
        var room = GAME.room;
        if (GAME.player.container.contains("Chiave Rossa") && this.aggressive) {
            GAME.write("Il Troll ti attacca, ti ruba la chiave rossa e chiude la porta a chiave.", "fight");
            GAME.player.takeDamage(2);
            this.container.take("Chiave rossa", GAME.player.container);
            GAME.room.gateways.ovest.actionLock();
        } else if (this.aggressive) {
            var first = true;
            var dot = setInterval(()=> {
                if (GAME.player.state == State.DEAD || GAME.room != room) {
                    clearInterval(dot);
                }
                if (!first) {
                    App.clearLineAndPrompt();
                    App.clearCaret();
                }
                //GAME.write("Il Troll ti colpisce.", "fight");
                GAME.player.takeDamage(1);

                App.newLine();
                first = false;
            }, 6000);
        }
    },
    onReceive: function(item) {
        if (item.name === 'pezza' && this.container.contains('Chiave rossa')) {
            console.info (this.container);
            this.container.give("Chiave rossa", GAME.player.container);
            GAME.write("Il troll accetta la pezza sporca e si calma. in cambio ti restituisce la chiave rossa!");
            this.aggressive = false;
            return true;
        }
        GAME.write("Il troll non vuole " + item.nameWithArticle());
        return false;
    }
});

gamedata.characters.vecchia = new Character({
    name: "vecchietta",
    sinonimi: ["vecchia","vecchina","signora"],
    description: "Una vecchietta simpatica",
    articolo: "una",
    maxHealth: 10,
    health: 10,
    attackPower: 2,
    defensePower: 2,
    volume: 10,
    items: [
        new Item({
            name: "bastone",
            articolo: "il",
            attackPower: 4
        }),
        new Item({
            name: "forchetta",
            articolo: "una",
            attackPower: 1
        }),
        new Item({
            name: "cucchiaio",
            articolo: "un",
            attackPower: 2
        })
    ],
    onMeet: function() {
        GAME.write( "La signora dice - Buongiorno giovanotto -");
        this.attack(GAME.player);
    },
    onAttack: function() {
        GAME.write( "La signora è imbufalita");
        this.attack(GAME.player, this.container.get('bastone'));
    }
});

gamedata.rooms = [
    new Room({
        name: "Ingresso",
        description: "Ti trovi all'ingresso di una vecchia casa vittoriana.",
        items: [
            gamedata.items.scrignoDorato,
            gamedata.items.mela,
            new Item({
                name: "pezza",
                articolo: "una",
                description: "Una pezza sporca e bagnata",
                onTake: function() {
                    return {
                        text: "Fa veramente schifo, la sollevi e la getti via",
                        result: "KO"
                    }
                },
                volume: 1
            })
        ],
        gateways: {
            nord: new Gateway({
                name: "scale",
                articolo: "le",
                destination: "Salotto",
                description: "Delle scale ripide scendono nel buio",
                actions: ["scendi", "prendi"]
            }),
            est: new Gateway({
                name: "porta rossa",
                key: "Chiave Rossa",
                articolo: "la",
                state: State.LOCKED,
                actions: [],
                description: "Una vecchia porta ricoperta di vernice rossa, con delle spaccature.",
                onOpen: function() {
                    GAME.write("La porta si apre e lascia intravedere una stanza buia da cui esce odore di muffa e di colla");
                    return true;
                },
                onBlocked: function() {
                    GAME.write("La porta rossa non si apre. Sembra che sia stata chiusa a chiave");
                    return false;
                },
                destination: "Sgabuzzino"
            })
        }
    }),
    new Room({
        name: "Salotto",
        description: "Ti trovi in un'ampia stanza, un salotto.",
        items: [
            new Item({
                name: "Chiave Rossa",
                articolo: "la",
                volume: 1
            })
        ],
        characters: [
            gamedata.characters.vecchia
        ],
        gateways: {
            sud: new Gateway({
                name: "scale",
                articolo: "le",
                destination: "Ingresso"
            })
        }
    }),
    new Room({
        name: "Sgabuzzino",
        description: "Ti trovi in una stanza piccola e strettissima.",
        gateways: {
            ovest: new Gateway({
                name: "Porta rossa",
                key: "Chiave Rossa",
                state: State.OPEN,
                destination: "Ingresso",
                onClose: function() {
                    GAME.write("La porta si chiude con un rumore assordante!");
                    return true;
                },
                onLock: function() {
                    GAME.write("La porta è stata chiusa a chiave!");
                    return true;
                }
            })
        },
        characters: [
            gamedata.characters.troll
        ]
    })
];

gamedata.characters.player = new Character({
    name: "Pancrazio",
    maxHealth: 20,
    health: 20,
    attackPower: 4,
    defensePower: 4,
    player: true,
    capacity: 5,
    items: [
        gamedata.items.portafoglio,
        new Item({
            name: "Chiave Rossa",
            articolo: "la",
            volume: 1,
            attackPower: 1
        })
    ]
});

GAME.configure({
    name: "Un'avventura eccezionale",
    player: gamedata.characters.player,
    rooms: gamedata.rooms,
    start: "Ingresso"
});

// console.info(GAME.objectsByType);

/* var packed = pack(GAME);

console.info(packed);

var unpacked = unpack(packed);

console.info(unpacked); */
