const State = {
    LOCKED: 0,
    CLOSED: 1,
    OPEN: 2,
    ALIVE: 3,
    WOUNDED: 4,
    DEAD: -1,
    BROKEN: 5
};

const Gender = {
    MALE: 1,
    FEMALE: 2
}

var progressive = 0;

String.prototype.plain = function () {
    return this.toLowerCase().trim();
};

String.prototype.sameFirstWord = function(other) {
    if (typeof other === "undefined") return false;
    return this.plain().split(" ")[0] == other.plain().split(" ")[0];
};

String.prototype.compare = function(other) {
    if (typeof other === "undefined") return false;
    return (this.plain() == other.plain()) || this.sameFirstWord(other);
};

String.prototype.ucfirst = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
String.prototype.formatSentence = function() {
    let w = '<b class="item">';
    return this.replace(`in ${w}il`, `nel${w}`)
        .replace(`in ${w}la`, `nella${w}`)
        .replace(`in ${w}lo`, `nello${w}`)
        .ucfirst();
}

var GAME = {
    objects: []
};

var Adventure = function(options) {
    var obj = {
        /* CUSTOM VARS (impostabili da configuratore) */
        name: "",
        player: null,
        rooms: [],
        start: "",
        room: null,
        /* END CUSTOM VARS */

        /* variabili gestite da oggetto (non modificare) */
        objects: {},
        objectsByType: {},
        encryptBackup: true,

        /* CONSTRUCTOR */
        construct: function() {
            var _this = this; // use _this in functions

            return this;
        },

        /* CONFIGURATOR: overwrite object defaults */
        configure: function(options) {
            for (prop in options) {
                this[prop] = options[prop];
            }

            this.room = this.getRoom(this.start);

            return this;
        },

        register: function(Obj) {
            if (typeof this.objectsByType[Obj.type.toLowerCase()] === "undefined")
                this.objectsByType[Obj.type.toLowerCase()] = {};
            this.objectsByType[Obj.type.toLowerCase()][Obj.id] = Obj;

            this.objects[Obj.id] = Obj;
        },

        saveValue: function(key, val) {
            if (val instanceof Object || Array.isArray(val)) {
                val = JSON.stringify(val)
            }
            localStorage.setItem(key, this.encryptBackup ? btoa(val) : val);
        },

        loadValue: function(key) {
            var val = this.encryptBackup ? atob(localStorage.getItem(key)) : localStorage.getItem(key);

            if (!val) return false;

            if (val instanceof Object || Array.isArray(val)) {
                val = JSON.parse(val);
            }
            return val;
        },

        backup: function() {
            for (id in this.objects) {
                this.saveValue(id, this.backupObj(this.objects[id]));
            }
            this.saveValue('room', this.room.id);
            this.saveValue('name', this.name);
        },

        restore: function() {

            if (!this.loadValue('room')) {
                return false;
            }

            for (id in this.objects) {
                this.objects[id] = {...this.objects[id], ...this.restoreObj(this.loadValue(id))};
            }

            this.room = this.objects[this.loadValue('room')];
            this.name = this.objects[this.loadValue('name')];

            return true;
        },

        restoreObj: function(Bkobj) {

            var Obj = {};

            for(key in Bkobj) {

                if (Obj[key] === null) {
                    continue;
                }

                if (Bkobj[key] instanceof Object && typeof Bkobj[key].ref !== "undefined") {
                    Obj[key] = this.objects[Bkobj[key].ref];
                } else if (Array.isArray(Bkobj[key]) || Obj[key] instanceof Object) {
                    Obj[key] = {...Obj[key], ...this.restoreObj(Obj[key])};
                }
            }

            return Obj;
        },

        backupObj: function(Obj) {

            var row = {};

            for(key in Obj) {

                if (Obj[key] instanceof Function) {
                    continue;
                }

                row[key] = undefined;

                if (Obj[key] instanceof Object && typeof Obj[key].id !== "undefined") {
                    row[key] = { ref: Obj[key].id };
                } else if (Array.isArray(Obj[key])) {
                    row[key] = this.backupObj(Obj[key]);
                } else if (Obj[key] instanceof Object) {
                    row[key] = this.backupObj(Obj[key]);
                } else {
                    row[key] = Obj[key];
                }
            }

            return row;
        },

        command(line) {

            var action = this.analyze(line);
            var response = "";
            var valid = true;

            // console.info(action);

            if (this.player.state == State.DEAD) {

                switch (action.command) {
                    case "load":
                        if (this.restore())
                            this.write('Gioco salvato ricaricato');
                        else
                            this.write('Non è presente alcun gioco salvato');
                        break;
                    default:
                        this.write('Sei morto. Per ricominciare il gioco ricarica la pagina web o carica il gioco salvato (\'load\')');
                }

                return true;
            }

            switch (action.command) {

                case "save":
                    this.backup();
                    this.write('Gioco salvato');
                    break;

                case "load":
                    if (this.restore())
                        this.write('Gioco salvato ricaricato');
                    else
                        this.write("Non è presente alcun gioco salvato");
                    break;

                case "dove":
                    if (action.target == "vado" || action.target == "posso andare") {
                        this.write(this.room.gatewaysToString());
                    } else {
                        this.write(this.describeEnvironment());
                    }
                    break;

                case "status":
                case "stato":
                case "player":
                case "come":
                    this.write(this.player.status());
                    break;

                case "inventario":
                case "cosa":
                case "quanto":
                    this.write(this.player.inv());
                    break;

                case "esamina":
                case "guarda":
                case "descrivi":
                case "analizza":
                case "osserva":

                    if (action.target == "" || this.room.is(action.target)) {
                        this.write(this.describeEnvironment());
                        break;
                    }


                    else if (this.lookList(action.target, this.room.gateways)) {}// controllo gateways
                    else if (this.lookList(action.target, this.room.characters)) {}// controllo characters
                    else if (this.lookContainer(action.target, this.room.container)) {}// controllo item nella stanza
                    else if (this.lookContainer(action.target, this.player.container)) {}// controllo item del player
                    else this.write("Non riesci a trovare " + action.target);

                    break;

                case "direzioni":
                case "uscite":
                case "porte":
                    this.write(this.room.gatewaysToString());
                    break;

                case "vai":
                    this.move(action.target);
                    break;

                case "raccogli":
                case "prendi":

                    if (action.item) {
                        let target = this.room.findCharacter(action.target)
                        if (!target) {
                            this.write(action.target.ucfirst() + " non c'è");
                            break;
                        } else {
                            this.player.steal(action.item, target);
                            break;
                        }
                    } else if (!this.room.container.contains(action.target)) {
                        let found = false;
                        for (id in this.room.container.items) {
                            if (this.room.container.items[id].type == "Container" && this.room.container.items[id].state == State.OPEN) {
                                this.player.container.take(action.target, this.room.container.items[id]);
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            for (id in this.player.container.items) {
                                if (this.player.container.items[id].type == "Container" && this.player.container.items[id].state == State.OPEN) {
                                    this.player.container.take(action.target, this.player.container.items[id]);
                                    break;
                                }
                            }
                        }

                    } else {

                        this.player.container.take(action.target, this.room.container);
                    }

                    valid = true;

                    break;

                case "getta":
                case "butta":
                case "abbandona":
                case "lascia":
                    this.player.container.give(action.target, this.room.container);
                    break;

                case "metti":
                case "dai":
                    var pre;

                    let itemName = action.item;
                    let receiverName = action.target;

                    if (item = this.player.container.get(itemName)) {

                        if (character = this.room.findCharacter(receiverName)) {
                            if (typeof character.onReceive === 'function') {
                                if (character.onReceive(item)) {
                                    this.player.container.give(itemName, character.container);
                                    this.write("Hai dato " + item.nameWitharticle() + " a " + character.nameWitharticle());
                                    break;
                                }
                            } else {
                                this.player.container.give(itemName, character.container);
                                this.write("Hai dato " + item.nameWitharticle() + " a " + character.nameWitharticle());
                                break;
                            }
                        }

                        if (container = this.room.container.get(receiverName) || this.player.container.get(receiverName)) {

                            if (container.type !== 'Container') {
                                this.write('Non puoi mettere niente dentro ' + container.nameWitharticle());
                                break;
                            }

                            if (container.state === State.CLOSED || container.state === State.LOCKED) {
                                this.write(container.nameWitharticle() + " è chiuso");
                                break;
                            }

                            if (typeof container.onReceive === 'function') {
                                if (container.onReceive(item)) {
                                    if (this.player.container.give(itemName, container)) {
                                        this.write("Hai messo " + item.nameWitharticle() + " dentro " + container.nameWitharticle());
                                    }
                                    break;
                                }
                            } else {
                                if (this.player.container.give(itemName, container)) {
                                    this.write("Hai messo " + item.nameWitharticle() + " dentro " + container.nameWitharticle());
                                }
                                break;
                            }
                        }

                    } else  {
                        this.write("Non hai " + itemName);
                        break;
                    }

                    break;

                case "usa":
                case "apri":

                    // controllo le gateways (porte)
                    for (id in this.room.gateways) {
                        if (this.room.gateways[id].is(action.target)) {

                            var door = this.room.gateways[id];

                            if (door.isLocked()) {

                                if (action.item != "") {

                                    if (this.player.container.contains(action.item)) {
                                        let item = this.player.container.get(action.item);
                                        this.write("Provi ad aprire " + door.nameWitharticle() + " con " + item.nameWitharticle());

                                        door.actionOpen(action.item);
                                        return true;

                                    } else {

                                        this.write("Non hai " + action.item);
                                        return true;
                                    }

                                } else {

                                    this.write("Provi ad aprire " + door.nameWitharticle());
                                    door.actionOpen();
                                    return true;
                                }

                            } else if (door.isClosed()) {

                                door.open();
                                return true;

                            } else {

                                this.write(door.nameWitharticle() + " è già aperta");
                                return true;
                            }
                        }
                    }

                    if (this.room.container.contains(action.target)) {
                        this.write(this.openContainer(this.room.container.get(action.target), action.item));
                        return true;
                    }

                    if (this.player.container.contains(action.target)) {
                        this.write(this.openContainer(this.player.container.get(action.target), action.item));
                        return true;
                    }

                    this.write("Non capisci bene cosa fare");
                    break;

                case "colpisci":
                case "uccidi":
                case "attacca":
                    var attacked = false;
                    this.room.characters.map(character => {
                        if (character.is(action.target)) {
                            if (action.item) {
                                let item = GAME.player.container.get(action.item);
                                if (!item) GAME.write("Non hai " . action.item);
                                GAME.player.attack(character, item);
                            } else {
                                GAME.player.attack(character);
                            }
                            attacked = true;
                            return true;
                        }
                    });

                    if (!attacked) {
                        GAME.write("Non vedi " + action.target);
                        return true;
                    }

                    break;

                case "ruba":

                    if (!action.item) break;
                    let target = this.room.findCharacter(action.target)
                    if (!target) {
                        this.write(action.target.ucfirst() + " non c'è");
                    } else if (!target.container.contains(action.item)) {
                        this.write(target.nameWitharticle() + " non ha " + action.item);
                    } else {
                        if (!this.player.steal(action.item, target)) {
                            this.write("Nulla di fatto");
                        }
                    }

                    break;

                default:
                    valid = false;

                    if (typeof this.room !== "undefined" && Object.keys(this.room.gateways).length > 0) {
                        for (id in this.room.gateways) {
                            if (this.room.gateways[id].actions && this.room.gateways[id].actions.indexOf(action.command) > -1) {
                                this.move(action.target);
                                return true;
                            }
                        }
                    }

                    if (!this.room.container.contains(action.target)) {

                        for (id in this.room.container.items) {
                            if (this.room.container.items[id].type == "Container" && this.room.container.items[id].state == State.OPEN) {
                                let item = this.room.container.items[id].get(action.target);
                                if (typeof item['on' + action.command.ucfirst()] === "function") {
                                    item['on' + action.command.ucfirst()](action.item);
                                    return true;
                                }
                            }
                        }

                        for (id in this.player.container.items) {
                            let item = this.player.container.get(action.target);
                            if (typeof item['on' + action.command.ucfirst()] === "function") {
                                item['on' + action.command.ucfirst()](action.item);
                                return true;
                            }

                            if (this.player.container.items[id].type == "Container" && this.player.container.items[id].state == State.OPEN) {
                                let item = this.player.container.items[id].get(action.target);
                                if (typeof item['on' + action.command.ucfirst()] === "function") {
                                    item['on' + action.command.ucfirst()](action.item);
                                    return true;
                                    break;
                                }
                            }
                        }

                    } else {
                        let item = this.room.container.get(action.target);
                        if (typeof item['on' + action.command.ucfirst()] === "function") {
                            item['on' + action.command.ucfirst()](action.item);
                            return true;
                        }
                    }
            }

            return valid;
        },

        lookList: function(objectName, list) {
            var response = "";
            for (id in list) {
                let target = list[id];
                if (target.is(objectName) || objectName.compare(id)) {
                    if (typeof target.description !== "undefined" && target.description != "") {
                        this.write(target.description);
                        return true;
                    } else {
                        this.write("Guardi " + (typeof target.nameWitharticle === 'function' ? target.nameWitharticle() : target.name) + ", ma non noti nulla di particolare");
                        return true;
                    }
                }
            }
            return false;
        },

        lookContainer: function(itemName, container) {
            var response = "";
            if (container.contains(itemName)) {

                let item = container.get(itemName);

                if (typeof item.description != "undefined") {
                    response += item.description;
                }

                if (item.type == "Container" && item.state == State.OPEN) {
                    if (item.count() > 0)
                        response += (response?"<br>":"") + item.nameWitharticle() + " contiene " + item.toString();
                    else
                        response += (response?"<br>":"") + item.nameWitharticle() + " non contiene nulla";
                }

                if (response) {
                    this.write(response);
                    return true;
                }

                response += "Guardi " + item.nameWitharticle() + ", ma non noti nulla di particolare";
                this.write(response);
                return true;

            } else {

                // cerco l'item nei container aperti
                for (id in container.items) {
                    let item = container.items[id];
                    if (item.type == "Container" && item.state == State.OPEN) {
                        let subitem = item.get(itemName);
                        if (subitem) {
                            response += "Controlli " + item.nameWitharticle() + " e vedi " + subitem.nameWitharticle();
                            if (subitem.description) response += "<br>" + subitem.description.formatSentence();
                            else response += "<br>" + "Non noti nulla di particolare";

                            this.write(response);
                            return true;
                        }
                    }
                }
            }
            return false;
        },

        openContainer: function(container, key) {
            var response = "";
            if (container.state == State.LOCKED) {

                if (key != "") {

                    if (this.player.container.contains(key)) {
                        let item = this.player.container.get(key);
                        response = "Provi ad aprire " + container.nameWitharticle() + " con " + item.nameWitharticle();

                        //if (key.plain() == container.key.plain()) {
                        if (key.compare(container.key)) {
                            if (typeof container.onOpen != "undefined") {
                                let r = container.onOpen();
                                if (r.result == "OK") {
                                    container.state = State.OPEN;
                                    response += "<br>" + container.nameWitharticle() + " si apre. Al suo interno trovi: " + container.toString();
                                }
                                if (typeof container.onOpen != "undefined") response += "<br>" + r.text;
                                return response.formatSentence();
                            } else {
                                container.state = State.OPEN;
                                response += "<br>" + container.nameWitharticle() + " si apre. Al suo interno trovi: " + container.toString();
                                return response.formatSentence();
                            }

                        } else {

                            if (typeof container.onBlocked != "undefined") {
                                response += "<br>" + container.onBlocked().text;
                                return response.formatSentence();
                            } else {
                                response += "<br>" + container.nameWitharticle() + " non si apre";
                                return response.formatSentence();
                            }

                        }

                    } else {

                        response = "Non hai " + key;
                        return response.formatSentence();
                    }

                } else {

                    response = "Provi ad aprire " + container.nameWitharticle();
                    if (typeof container.onBlocked != "undefined") {
                        response += "<br>" + container.onBlocked().text;
                        return response.formatSentence();
                    } else {
                        response += "<br>" + container.nameWitharticle() + " non si apre";
                        return response.formatSentence();
                    }
                }

            } else if (container.state == State.CLOSED) {

                response += container.nameWitharticle() + " si apre. Al suo interno trovi " + container.toString();
                if (typeof container.onOpen != "undefined") response += "<br>" + container.onOpen().text;
                container.state = State.OPEN;
                return response.formatSentence();

            } else {

                response = "Apri " + container.nameWitharticle() + ". ";
                if (container.toString()) response += "Al suo interno trovi " + container.toString();
                else response += "Non contiene nulla";
                return response.formatSentence();
            }
        },

        analyze: function(line) {

            line = line.replace(/[\?!-\.,:;"']/g, " ").replace(/(\s+)/g, " ").plain();

            let analysis = {};
            let strip = new RegExp("^(la|di|da|il|la|lo|i|gli|le|l'|sto|sta|ho)$");
            let leftpreps = new RegExp("^(con)$");
            let rightpreps = new RegExp("^(su|sulla|a|al|allo|alla|nel|nella|nello|nei|negli|nelle)$");

            let first = [];
            let second = [];
            var sep = '';

            analysis.input = words = line.split(' ');
            words = words.filter(word => !strip.test(word));

            analysis.command = words[0].trim();
            words.shift();

            words.map((word, i) => {
                words[i] = words[i].trim();
                if (!sep && leftpreps.test(word)) {
                    analysis.preposition = words[i];
                    sep = words[i] = '<';
                } else if (!sep && rightpreps.test(word)) {
                    analysis.preposition = words[i];
                    sep = words[i] = '>';
                } else if (!sep) {
                    first.push(word);
                } else {
                    second.push(word);
                }

            });

            first = first.join(' ').trim();
            second = second.join(' ').trim();

            analysis.item = sep ? (sep === '<' ? second : first) : '';
            analysis.target = (sep === '>' ? second : first)

            analysis.line = line;
            analysis.result = '!' + analysis.command + ' ' + (first + ' ' + sep + ' ' + second).trim();
            analysis.words = [];
            analysis.words.push(first);
            if (sep) analysis.words.push(sep);
            if (second) analysis.words.push(second);

            return analysis;
        },

        move: function(target) {
            if (typeof this.room.gateways === "undefined") {
                this.write("Ti guardi attorno ma non trovi la strada");
                return;
            }

            var gateway = this.room.gateways[target] || this.room.getGateway(target);

            if (typeof gateway === "undefined" || !gateway) {
                this.write("Ti guardi attorno ma non trovi la strada");
                return;
            }

            if (gateway.isLocked()) {
                this.write("Ci provi, ma non puoi passare");
                return;
            } else if (gateway.isClosed()) {
                this.write("Devi prima aprire");
                return;
            } else {

                var response = "";
                if (gateway.actions.length > 0) {
                    var rA = gateway.actions[Math.floor(Math.random() * gateway.actions.length)];
                    response += rA + " " + gateway.nameWitharticle() + " ed ";
                }

                this.room = this.getRoom(gateway.destination) || this.room;
                response += "entri in " + this.room.name;
                this.write(response);
                this.write(this.describeEnvironment());

                for (id in this.room.characters) {
                    if (this.room.characters[id].state == State.ALIVE && typeof this.room.characters[id].onMeet !== "undefined")
                        this.room.characters[id].onMeet();
                }
            }
        },

        write: function(text, mood) {
            if (!mood) mood = "game";
            App.write(text.formatSentence(), true, mood);
        },

        getRoom: function(roomName) {
            for (id in this.rooms) {
                if (this.rooms[id].is(roomName)) return this.rooms[id];
            }
            return false;
        },

        describeEnvironment: function() {
            var desc = this.room.description;
            var gateways = this.room.gatewaysToString();
            var items = this.room.container.toString();
            var characters = [];
            for (id in this.room.characters) {
                characters.push(
                    this.room.characters[id].nameWitharticle() +
                    (this.room.characters[id].state == State.DEAD?" mort" + (this.room.characters[id].gender == Gender.FEMALE?"a":"o") :"")
                );
            }
            var chars = characters.join(", ");
            desc += (items ? "<br>Vedi " + items : "")
            desc += (chars ? "<br>Noti la presenza di " + chars : "")
            desc += (gateways ? "<br>Direzioni: " + gateways : "");
            return desc;
        },
    };

    // ritorno istanza dell'oggetto
    obj.configure(options).construct();;
    return obj;
};

// base Gameobject class
var GameObject = {
    name: '',
    sinonimi: [],
    id: 0,
    type: this.constructor.name,
    articolo: '',
    volume: 1, // unità di volume base dell'oggetto

    _construct: function() {
        GAME.register(this);
        return this;
    },

    construct: function() {
        return this;
    },

    configure: function(options) {
        for (prop in options) {
            this[prop] = options[prop];
        }
        return this;
    },

    nameWitharticle: function() {
        return '<b class="item">'+ (this.articolo ? this.articolo + " ":"") + this.name + '</b>';
    },

    is: function(text) {
        if (this.name.compare(text)) return true;
        for (syn of this.sinonimi) {
            if (syn.compare(text)) return true;
        }
        return false;
    }
}

// Componente container
var Container = function(options) {
    var obj = {...GameObject, ...{ // extends GameObject

        owner: null,
        items: [],
        capacity: 1, // quante unità di volume può contenere (non è il numero oggetti)
        state: State.OPEN,
        key: "",
        id: ++progressive,
        type: this.constructor.name,

        contains: function(itemName) {
            for (id in this.items) {
                if (this.items[id].is(itemName)) return true;
            }
            return false;
        },

        get: function(itemName) {
            for (id in this.items) {
                if (this.items[id].is(itemName)) return this.items[id];
            }
            return false;
        },

        toString: function() {
            var desc = "";
            if (this.items) {
                let items = [];
                for (i in this.items) {
                    items.push(this.items[i].nameWitharticle());
                }
                desc += items.join(", ");
            }
            return desc;
        },

        delete: function(itemName) {
            for (id in this.items) {
                if (this.items[id].is(itemName)) {
                    this.items.splice(id, 1);
                    return true;
                }
            }
            return false;
        },

        // il volume finale del container (volume container più volume contenuti)
        totalVolume: function() {
            return this.volume + this.contentVolume();
        },

        // il volume dei contenuti
        contentVolume: function() {
            var volume = 0;
            for (i in this.items) {
                volume += this.items[i].volume;
            }
            return volume;
        },

        count: function() {
            return this.items.length;
        },

        // quanto volume può ancora incorporare
        space: function() {
            return this.capacity - this.contentVolume();
        },

        add: function(item) {
            this.items.push(item);
        },

        give: function(itemName, receiver) {
            var response = "";

            if (receiver.type !== "Container") {
                GAME.write("Non si può fare");
            }

            if (!itemName || !this.contains(itemName)) {
                GAME.write("Non lo riesci a trovare");
                return false;
            }

            var item = this.get(itemName);

            if (receiver.owner) {
                if (receiver.owner.player) {
                    if (typeof item.onTake != "undefined") {
                        if (!item.onTake()) {
                            return false;
                        }
                    }
                }
            }

            if (item.totalVolume() > receiver.space()) {
                if (receiver.owner && receiver.owner.player) {
                    GAME.write("Non puoi trasportare " + item.nameWitharticle());
                } else if (receiver.owner) {
                    GAME.write(receiver.owner.nameWitharticle() + " Non può trasportare " + item.nameWitharticle());
                } else {
                    GAME.write("Non c'è spazio in " + receiver.nameWitharticle() + " per " + item.nameWitharticle());
                }
                return false;
            }

            receiver.add(item);
            this.delete(itemName);

            if (receiver.owner && receiver.owner.type == "Character") {
                if (receiver.owner.player) GAME.write("Hai ottenuto " + item.nameWitharticle());
                else GAME.write(receiver.owner.nameWitharticle() + " ha ottenuto " + item.nameWitharticle());
            } else if (receiver.owner) {
                GAME.write(item.nameWitharticle() + " è ora in " + receiver.owner.nameWitharticle());
            } else {
                GAME.write(item.nameWitharticle() + " è ora in " + receiver.nameWitharticle());
            }

            return true;
        },

        take: function (itemName, sender) {
            return sender.give(itemName, this);
        }
    }};

    // ritorno istanza dell'oggetto
    obj.configure(options)._construct().construct();
    return obj;
};

var Character = function(options) {
    var obj = {...GameObject, ...{ // extends GameObject
        /* CUSTOM VARS (impostabili da configuratore) */
        maxHealth: 10,
        health: 10,
        capacity: 5,
        aggressive: false,
        state: State.ALIVE,
        gender: Gender.MALE,
        attackPower: 0,
        defensePower: 0,
        /* END CUSTOM VARS */

        id: ++progressive,
        type: this.constructor.name,
        player: false,
        container: [],

        /* variabili gestite da oggetto (non modificare) */

        /* CONSTRUCTOR */
        construct: function() {
            var _this = this; // use _this in functions

            this.container = new Container({
                owner: this,
                capacity: this.capacity
            });

            if (this.items) this.container.items = this.items;

            return this;
        },

        status() {
            var response = "Salute: " + (this.health / this.maxHealth) * 100 + "%";
            response += "<br>" + this.inv();
            return response;
        },

        isAlive() {
            return this.state !== State.DEAD;
        },

        isDead() {
            return this.state === State.DEAD;
        },

        inv() {
            var response = "";
            if (this.container.count() > 0) response += "Hai con te: " + this.container.toString();
            else response += "Non hai nulla con te";
            return response;
        },

        moveTo(room) {
            room.characters.push(this);
            if (typeof this.room === 'undefined') return;
            this.room.characters = this.room.characters.filter(character => {
                character.id !== this.id;
            });
        },

        getHealed(amount) {
            if (this.state == State.DEAD) return false;

            var result = this.health + amount;
            var oldh = this.health;
            this.health = (result > this.maxHealth ? this.maxHealth : result);
            var gained = this.health - oldh;

            if (this.player) GAME.write("Guadagni " + gained + " punti salute");
            else GAME.write(this.nameWitharticle() + "Guadagna " + gained + " punti salute");

            return gained;
        },

        takeDamage(amount) {

            if (this.state == State.DEAD) return false;

            var result = this.health - amount;
            var oldh = this.health;
            this.health = (result > 0 ? result : 0);

            var lost = oldh - this.health;
            if (lost<=0) {
                if (this.player) GAME.write("Non subisci danni", "fight");
                else GAME.write(this.nameWitharticle + " non subisce danni");
            }

            if (this.player) GAME.write("Perdi " + lost + " punti salute", "fight");
            else GAME.write(this.nameWitharticle() + " perde " + lost + " punti salute", "fight");

            if (this.health <= 0) {
                this.state = State.DEAD;
                if (this.player) {
                    GAME.write("Sei morto", "fight");
                } else {
                    GAME.write(this.nameWitharticle() + " muore", "fight");
                }

                if (!this.player) {
                    let cadavere = new Item({
                        name: "cadavere di " + this.nameWitharticle(),
                        articolo: "il",
                        volume: this.volume,
                        description: this.corpseDescription ? this.corpseDescription : ""
                    });

                    for (item of this.container.items) {
                        GAME.room.container.add(item);
                        GAME.write(this.nameWitharticle() + " lascia " + item.nameWitharticle());
                    }

                    GAME.room.container.add(cadavere);
                    GAME.room.deleteCharacterWithId(this.id);
                }

            }

            return (lost > 0);
        },

        getTotalDefense() {
            var total = this.defensePower;
            this.container.items.map(item => {
                total += typeof item.defensePower !== 'undefined' ? item.defensePower : 0;
            });
            return total;
        },

        getBestWeapon() {
            if (this.container.items.length == 0) return null;
            return this.container.items.reduce((max, item) => {
                return (max.attackPower > item.attackPower ? max : item);
            });
        },

        attack(other, weapon) {
            other.aggressive = true;

            if (!other.isAlive()) return false;

            if (!weapon && !this.player) {
                weapon = this.getBestWeapon();
            }

            function getRandomIntInclusive(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min + 1)) + min;
              }

            let critico = Math.random() > .8 ? getRandomIntInclusive(0,3) : 0;
            let parry = Math.random() > .7 ? getRandomIntInclusive(0,3) : 0;

            let attackPower = this.attackPower + (weapon && weapon.type == 'Item' ? weapon.attackPower : 0) + critico;
            let defensePower = other.getTotalDefense() + parry;

            if (this.player) {
                GAME.write("Attacchi " + other.nameWitharticle() + (weapon?" con " + weapon.nameWitharticle():""), "fight");
            } else {
                GAME.write(this.nameWitharticle() + " ti attacca " + (weapon?" con " + weapon.nameWitharticle():""), "fight");
            }

            if (critico) {
                if (this.player) {
                    GAME.write("Infliggi un colpo critico! (+" + critico +")", "fight");
                } else {
                    GAME.write(this.nameWitharticle() + " ti infligge un colpo critico! (+" + critico +")", "fight");
                }
            }

            if (parry) {
                if (other.player) {
                    GAME.write("Sei riuscito a parare", "fight");
                } else {
                    GAME.write(other.nameWitharticle() + " ha parato il colpo", "fight");
                }
            }

            if (attackPower > defensePower) {
                other.takeDamage(attackPower - defensePower);
                if (other.isDead()) {
                    return false;
                }
            } else {
                GAME.write('L\'azione non ha alcun effetto');
            }

            if (typeof other.onAttack === 'function' && other.isAlive()) {
                other.onAttack();
            } else if (!other.player && other.aggressive && other.isAlive()) {
                other.attack(this);
            }
        },

        steal(itemName, target) {
            response = '';
            item = target.container.get(itemName);
            if (!item) return false;

            if (this.player) response += 'Cerchi di rubare ' + item.nameWitharticle();
            else if (target.player) response += this.nameWitharticle() + ' cerca di rubarti ' + item.nameWitharticle();
            else response += this.nameWitharticle() + ' cerca di rubare ' + item.nameWitharticle() + ' a ' .target.nameWitharticle();

            if(Math.random() >= 0.7) {
                response += ' e ci riesci';
                GAME.write(response);
                target.container.give(item.name, GAME.player.container);
                return true;
            } else {
                response += ' ma non ci riesci';
                GAME.write(response);
                target.aggressive = true;
                target.attack(this);
                return false;
            }
        }
    }};

    // ritorno istanza dell'oggetto
    obj.configure(options)._construct().construct();
    return obj;
};

var Room = function(options) {
    var obj = {...GameObject, ...{ // extends GameObject
        /* CUSTOM VARS (impostabili da configuratore) */
        description: "",
        gateways: [],
        characters: [],
        /* END CUSTOM VARS */

        id: ++progressive,
        type: this.constructor.name,
        container: null,

        /* variabili gestite da oggetto (non modificare) */

        /* CONSTRUCTOR */
        construct: function() {
            var _this = this; // use _this in functions

            this.container = new Container({ owner: this, capacity: 20 });

            if (this.items) {
                this.container.items = this.items;
            }

            if (typeof characters !== 'undefined') {
                this.characters.map(character => {
                    character.room = this;
                });
            }

            if (typeof this.container !== 'undefined') {
                this.container.items.map(item => {
                    item.room = this;
                });
            }

            return this;
        },

        getGateway: function(gatewayName) {
            for (i in this.gateways) {
                //if (this.gateways[i].name.plain() == gatewayName.plain())
                if (this.gateways[i].is(gatewayName))
                    return this.gateways[i];
            }
            return false;
        },

        gatewaysToString: function() {
            var desc = "";
            if (this.gateways) {
                let dirs = [];
                for (i in this.gateways) {
                    dirs.push(this.gateways[i].name + " (" + i + ")");
                }
                desc += dirs.join(", ");
            }
            return desc;
        },

        findCharacter: function(name) {
            if (typeof this.characters === 'undefined') return false;
            for (id in this.characters) {
                if (this.characters[id].is(name)) return this.characters[id];
            }
            return false;
        },

        deleteCharacter: function(name) {
            if (typeof this.characters === 'undefined') return false;
            for (i in this.characters) {
                if (this.characters[i].name === name) {
                    this.characters.splice(i, 1);
                    return true;
                }
            }
            return false;
        },

        deleteCharacterWithId: function(objId) {
            if (typeof this.characters === 'undefined') return false;
            for (i in this.characters) {
                if (this.characters[i].id === objId) {
                    this.characters.splice(i, 1);
                    return true;
                }
            }
            return false;
        }


    }};

    // ritorno istanza dell'oggetto
    obj.configure(options)._construct().construct();;
    return obj;
};

var Item = function(options) {
    var obj = {...GameObject, ...{ // extends GameObject
        /* CUSTOM VARS (impostabili da configuratore) */
        volume: 1,
        attackPower: 0,
        defensePower: 0,
        /* END CUSTOM VARS */

        /* variabili gestite da oggetto (non modificare) */
        id: ++progressive,
        type: this.constructor.name,

        totalVolume: function() {
            return this.volume;
        }

    }};

    // ritorno istanza dell'oggetto
    obj.configure(options)._construct().construct();;
    return obj;
};

var Gateway = function(options) {
    var obj = {...GameObject, ...{ // extends GameObject
        /* CUSTOM VARS (impostabili da configuratore) */

        key: "",
        state: State.OPEN,
        destination: "",
        actions: [],
        description: "",
        onOpen: null,
        onBlocked: null,

        /* END CUSTOM VARS */

        /* variabili gestite da oggetto (non modificare) */
        id: ++progressive,
        type: this.constructor.name,

        open: function() {
            this.state = State.OPEN;
            let destRoom = GAME.getRoom(this.destination);
            let otherSide = destRoom.getGateway(this.name);
            otherSide.state = State.OPEN;
            if (typeof this.onOpen !== 'function') {
                GAME.write(this.nameWitharticle() + " si è aperta");
            }

        },

        close: function() {
            this.state = State.CLOSED;
            let destRoom = GAME.getRoom(this.destination);
            let otherSide = destRoom.getGateway(this.name);
            otherSide.state = State.CLOSED;
            if (typeof this.onClose !== 'function') {
                GAME.write(this.nameWitharticle() + " si è chiusa");
            }
        },

        lock: function() {
            this.state = State.LOCKED;
            let destRoom = GAME.getRoom(this.destination);
            let otherSide = destRoom.getGateway(this.name);
            otherSide.state = State.LOCKED;
            if (typeof this.onLock !== 'function') {
                GAME.write(this.nameWitharticle() + " è stata bloccata");
            }
        },

        actionOpen: function(itemName) {

            if (this.isOpen()) {
                return;
            }

            if (!this.isLocked()) {
                this.open();
                return true;
            }

            if (!itemName) {
                if (typeof this.onBlocked == 'function') {
                    this.onBlocked();
                    return false;
                } else {
                    GAME.write(this.nameWitharticle() + " non si apre");
                    return false;
                }
            }

            let item = GAME.player.container.get(itemName);

            if (item.is(this.key)) {
                if (typeof this.onOpen == 'function') {
                    if (this.onOpen()) {
                        this.open();
                    }
                    return true;
                } else {
                    this.open();
                    return true;
                }

            } else {

                if (typeof this.onBlocked == 'function') {
                    this.onBlocked();
                    return true;
                } else {
                    this.write(this.nameWitharticle() + " non si apre");
                    return true;
                }
            }
        },

        actionClose: function() {
            if (!this.isOpen()) return;

            if (typeof this.onClose == 'function') {
                if (this.onClose()) {
                    this.close();
                }
                return true;
            } else {
                this.close();
                return true;
            }
        },

        actionLock: function() {
            if (this.isLocked()) return;

            if (typeof this.onLock === 'function') {
                if (this.onLock()) {
                    this.lock();
                }
                return true;
            } else {
                this.lock();
                return true;
            }
        },

        isClosed: function() {
            return this.state == State.CLOSED;
        },

        isLocked: function() {
            return this.state == State.LOCKED;
        },

        isOpen: function() {
            return this.state == State.OPEN;
        }

    }};

    // ritorno istanza dell'oggetto
    obj.configure(options)._construct().construct();;
    return obj;
};

var pack = function(Obj) {
    var serializable = [];
    for(key in Obj) {
        if (Obj[key] instanceof Function) {
            continue;
        } if (Obj[key] instanceof Object || Obj[key] instanceof Array) {
            serializable[key] = pack(Obj[key]);
        } else {
            serializable[key] = Obj[key];
        }
    }
    return JSON.stringify(serializable);
}

var unpack = function(Json) {
    return JSON.parse(Json);
}

/* var register = function(Obj) {
    if (typeof GAME.objects[Obj.type] === "undefined")
        GAME.objects[Obj.type] = {};
    GAME.objects[Obj.type][Obj.id] = Obj;
} */